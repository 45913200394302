<template>
  <el-dialog
    v-if="openDialog"
    title="标签管理"
    :visible.sync="openDialog"
    width="50%"
    :before-close="close"
  >
    <el-form
        ref="form"
        :model="form"
        :hide-required-asterisk="true"
        v-loading="dialogLoading"
      >
      <el-form-item label="">
          <div v-for="(item, index) in tagList" :key="index" class="tag-boxs">
            <h2 v-if="item.tag_count!=0">{{item.cat_name}}</h2>
              <!-- 多选 -->
            <el-checkbox-group v-model="form.tag_ids">
                <el-checkbox v-for="(itemtag, indextag) in item.tags" :key="indextag" :label="itemtag.id">{{itemtag.tag_name}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="close">取 消</el-button>
    <el-button
        type="primary"
        @click="submitForm()"
        v-loading="loading"
        >确 定</el-button
    >
    </span>
  </el-dialog>
</template>

<script>
import API from "@/api";
export default {
 data(){
    return{
        detail:{},
        loading: false,
        openDialog: false,
        form:{
            tag_ids:[]
        },
        dialogLoading:false,
        tagList:[],
        row:[]
    }
 },
 mounted(){
    this.getTargetTree();
 },
 methods:{
    openDialogFun(data){
        this.detail = data;
        this.openDialog = true;
        this.getTargetTree()
    },
    // 获取标签树 以及 up主
    getTargetTree(){
      this.row = [];
      this.dialogLoading = true;
      let apiOne = API.getTagTree({is_up:false});
      let apiTwo = API.getEchoTagTree({pro_id:this.detail.product_id})
      Promise.all([apiOne,apiTwo]).then((res)=>{
        // 所有标签数据
        if(!res[0].code){
          // 方便标签选中
          res[0].data.forEach(v=>{
            let list = []
              v.tags.forEach(x=>{list.push(x.id)})
              this.row.push({check:[],type:'check',list:list,input:"",father:v.id});
          })
          this.tagList = res[0].data
        }else{
          this.tagList = []
        }
        // 回显数据--当前产品标签id
        if(!res[1].code && res[1].data.tagIds){
            // 编辑数据回显 
          let value = res[1].data.tagIds;
          this.row.forEach(v=>{
            if(v.list.length){
              v.list.forEach(x=>{
                if(value.includes(x)){
                  this.form.tag_ids.push(x);
                }
              })
            }
          })
        }
        this.dialogLoading = false;
      })
    },
    close() {
      this.resetData();
    },
    submitForm() {
        if(this.form.tag_ids.length==0){
            this.$message({
                message: '请选择标签',
                type: 'warning'
            });
            return false
        }
        let req = {
            pro_id:this.detail.product_id,
            tag_ids:this.form.tag_ids
        };
        this.loading = true;
        API.prodyctTagList(req).then((res)=>{
            if(!res.code){
                this.$message.success("标签管理成功");
                this.resetData()
                this.$parent.getList();
            }else{
                this.$message.error(res.msg);
                this.resetData()
            }
        })
    },
    resetData(){
        this.loading = false;
        this.form.tag_ids = [];
        this.openDialog = false;
    }
 }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body{
    height: 600px;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    h2{
        font-size: 14px;
    }
    .el-checkbox{
        margin-right: 20px;
    }
}
::v-deep .dialog-footer{
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>